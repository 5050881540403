import React from "react"
import { Link } from "gatsby"


const PostFooter = () => {
    return (
        <div style={{marginTop: "0", fontStyle: "italic"}}>
            Thanks for reading! Feel free to <Link style={{color: "inherit !important"}} to="/about">reach out</Link> if you have any interesting SQL or data projects that I can help with.
        </div>
    )
}

export default PostFooter